.newDocumentPage {
    margin: 10px;
}
.uploadDocumentInfo {
    max-width: 800px;
    width: 100%;
    margin-top: 30px;
}
.buttonDiv {
    max-width: 785px;
    width: 100%;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.uploadBox {
    max-width: 400px;
    margin-top: 30px;
    width: 100%;
}
.dragArea {
    height: 250px;
    border: 2px dashed #393e46;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
}
.dragArea.disabled {
    border: 2px dashed #ccc;
}
.dragArea.disabled:hover {
    cursor: not-allowed;
}
.dragArea.disabled .icon {
    color: grey;
}
.dragArea.disabled h3 {
    color: grey;
}
.dragArea:hover {
    background-color: #ddd;
}
.dragArea h3 {
    font-family: 'Roboto';
}
.dragArea p {
    color: gray;
    font-family: 'Roboto';
    margin-top: -10px;
}
.dragArea .icon {
    margin-top: 20px;
    font-size: 40px;
    color: #393e46;
}

/* .dragArea .header {
    font-size: 18px;
    font-weight: 500;
    color: #007b55;
} */

/* .dragArea .support {
    font-size: 12px;
    color: gray;
    margin: 10px 0 15px 0;
} */
/* 
.dragArea .button {
    font-size: 20px;
    font-weight: 500;
    color: #007b55;
    cursor: pointer;
} */

.dragArea.active {
    border: 2px solid #007b55;
}

.dragArea img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.imageContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    flex-wrap: wrap;
    width: 420px;
}

.image {
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    display: inline-flex;
}

.imageInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}
