.singupContent {
  max-width: 600px;
  text-align: center;
  margin: 10px auto;
}
.singupContent form {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: -1px -1px 8px -1px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: -1px -1px 8px -1px rgba(0, 0, 0, 0.52);
  box-shadow: -1px -1px 8px -1px rgba(0, 0, 0, 0.52);
  margin-bottom: 30px;
}
.upperBoxSignup {
  background-image: linear-gradient(to bottom right, #585d63, #222831);
  /* background-image: linear-gradient(to bottom right, #00ab55, #007b55); */
  padding: 1px 0 40px 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  margin-bottom: 30px;
}

.upperBoxSignup h4 {
  color: #fff;
  font-family: play;
  font-size: 25px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.upperBoxSignup img {
  width: 42%;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.headingSignup {
  font-family: play;
  color: #393e46;
  font-size: 45px;
  text-align: center;
}
.formFieldsBoxSignup {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: -1px -1px 8px -1px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: -1px -1px 8px -1px rgba(0, 0, 0, 0.52);
  box-shadow: -1px -1px 8px -1px rgba(0, 0, 0, 0.52);
  margin-bottom: 30px;
  margin-top: -65px;
  padding: 10px;
  padding-bottom: 30px;
}
.loginBtn {
  font: 14px 'Roboto';
}

.validationProfileAlert {
  max-width: 400px;
  width: 95%;
  position: fixed;
  right: 1%;
  top: 5%;
  margin: 5px;
  z-index: 500;
}

@media (max-width: 420px) {
  .headingSignup {
    margin-bottom: -40px;
    margin-top: 10px;
    font-size: 45px;
  }
}

@media (max-width: 540px) {
  .upperBoxSignup img {
    width: 60%;
  }
}
