.documentFile {
    max-width: 900px;
    width: 100%;
    height: auto;
    margin: 50px 0 50px;
}

.videoFile video {
    max-width: 700px;
    width: 100%;
}
.audioFile {
    width: 100%;
}
.imageFile {
    max-width: 700px;
    width: 100%;
    height: 100%;
}

.imageFile img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: 400px;
    cursor: pointer;
}

.swiper {
    width: 100% !important;
    height: 100%;
}

.swiperSlide img {
    width: 100% !important;
    height: 400px !important;
    cursor: pointer;
}

.docFile {
    width: 900px;
    height: 400px;
}
@media (min-width: 700px) and (max-width: 900px) {
    .docFile {
        width: 700px;
    }
}
@media (min-width: 600px) and (max-width: 700px) {
    .docFile {
        width: 600px;
    }
}
@media (min-width: 500px) and (max-width: 600px) {
    .docFile {
        width: 500px;
    }
}
@media (min-width: 400px) and (max-width: 500px) {
    .docFile {
        width: 400px;
    }
}
@media (min-width: 350px) and (max-width: 400px) {
    .docFile {
        width: 350px;
    }
}

@media (max-width: 350px) {
    .docFile {
        width: 100%;
    }
}
