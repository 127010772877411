.downloadedBy {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

@media (min-width: 900px) and (max-width: 1280px) {
    .downloadedBy {
        width: 65%;
    }
}

@media (min-width: 700px) and (max-width: 900px) {
    .downloadedBy {
        width: 85%;
    }
}

@media (max-width: 700px) {
    .downloadedBy {
        width: 100%;
    }
}
