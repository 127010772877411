.editDocumentPage {
    margin: 10px;
}
.editSwitch {
    max-width: 800px;
    width: 100%;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.actionButtons {
    max-width: 600px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
