.viewDocumentPage {
    margin: 10px;
}
.documentFile {
    max-width: 900px;
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documentInfo {
    margin-top: 0;
    max-width: 100%;
    width: 100%;
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.documentInfoPaper {
    max-width: 700px;
}
.privateDocImage {
    max-width: 450px;
    max-height: 450px;
    width: 100%;
    height: 100%;
}
